.base-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main-content {
	flex-grow: 1;
	display: flex;
}
