@import '../utils/vars.scss';

.react-datepicker-wrapper {
	display: block;
}

.react-datepicker {
	font-size: 14px;
	line-height: 20px;
	font-family: $base-font-family;
	border-color: $input-border-color;
	border-radius: 4px;

	&-popper {
		padding-top: 2px !important;
	}

	&__current-month {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: $input-color;
	}

	&__time-name,
	&__day,
	&__day-name {
		font-size: 14px;
		line-height: 20px;
	}

	&__triangle {
		transform: translate(15px) rotate(45deg) !important;
		width: 10px !important;
		height: 10px;
		border: $main-border;
		background-color: #fff;
		top: 3px !important;

		&::before,
		&::after {
			display: none;
		}
	}

	&__header {
		background-color: #fff;
		border-bottom: none;
		padding: 10px 13px;
	}

	&__month {
		margin: 0;
	}

	&__navigation {
		text-indent: unset;

		&::before {
			content: '\e901';
			font-family: 'icomoon';
			font-size: 10px;
			transform: rotate(90deg);
		}

		&:last-of-type {
			&::before {
				transform: rotate(-90deg);
			}
		}
	}

	&__navigation-icon {
		display: none;
	}

	&__day-names {
		padding-top: 12px;
		margin: 0;
	}

	&__day-name {
		width: auto;
		margin: 0 20px 0 0;

		&:last-of-type {
			margin: 0;
		}
	}

	&__day {
		width: 37px;
		height: 30px;
		outline: none;
		padding-top: 5px;
		margin: 0 0 5px 0;

		&--selected {
			background-color: $astral;
			border-radius: 3px;
		}

		&--outside-month {
			color: #777;
		}

		&--today {
			font-weight: normal;
		}
	}

	&__close-icon {
		&::after {
			display: none;
		}

		&::before {
			content: '\e902';
			font-family: 'icomoon';
			color: $input-placeholder-color;
			font-size: 8px;
			font-weight: 900;
			margin-right: 5px;
		}

		&:hover::before {
			color: #777;
		}
	}
}
