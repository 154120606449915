@import '/node_modules/include-media/dist/_include-media.scss';
@import '../../../styles/utils/vars';

.footer {
	border-top: 1px solid #e5e5e5;
	background-color: #f7f7f7;
	margin-top: 40px;
	padding: 20px 0;
	font-size: 11px;
	line-height: 16px;
	color: #777;
	font-family: Helvetica, Arial, sans-serif;

	@include media('<phone') {
		text-align: center;
	}

	strong {
		color: #000;
	}

	p {
		margin: 0;
	}

	&__version {
		text-align: right;
		@include media('<phone') {
			text-align: center;
		}
	}
}
