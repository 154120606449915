@import '../../../../styles/utils/vars';
@import '../../../../styles/utils/mixins';

.toggle {
	margin: 0;
	border: none;
	background: none;
	box-shadow: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 14px;
	line-height: 20px;
	padding: 9px 14px;
	color: $astral;
	cursor: pointer;
	text-align: left;
}

.dropdown {
	display: none;
	padding: 0;
	margin: 0;
	list-style: none;
}

.dropdownOpen {
	display: block;
}

.iconRotate {
	transform: rotate(180deg);
}

.subLink {
	padding-left: 34px !important;
}

.icon {
	font-size: 12px;
	margin-left: 10px;
}
