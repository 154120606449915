@import '../../../../styles/utils/vars';
@import '../../../../styles/utils/mixins';

.item {
	margin-bottom: 4px;

	&:last-of-type {
		margin-bottom: 0;
	}
}
