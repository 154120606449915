@import '../../../../styles/utils/vars';
@import '../../../../styles/utils/mixins';

.link {
	display: block;
	font-size: $main-font-size;
	line-height: $main-line-height;
	padding: 10px 14px;

	&.activeLink {
		background-color: $btn-primary;
		border-radius: 3px;
		color: #fff;

		&:hover {
			color: #fff;
		}
	}
}
