@import '../../../styles/utils/vars';
@import '../../../styles/utils/mixins';

.frame {
	height: max-content;
	border: $main-border;
	border-radius: 3px;
	margin-bottom: 22px;

	&__header {
		display: flex;
		min-height: 40px;
		justify-content: space-between;
		align-items: center;
		background-color: $light-bcgr;
		border-radius: 3px 3px 0 0;
		padding: 4px 15px;
	}

	&__header-left {
		max-width: 50%;
		flex: 0 0 50%;
	}

	&__title {
		p {
			display: inline-block;
			margin-right: 21px;
		}
	}

	&__buttons {
		display: flex;

		.btn {
			margin-left: 12px;
		}
	}

	&__body {
		padding: 15px;
		border-top: 1px solid #ddd;
		background-color: #fff;
	}
}
