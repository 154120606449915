* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

#root {
	min-height: 100%;
}

body {
	font-family: $base-font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
	font-size: $main-font-size;
	line-height: $main-line-height;
	color: $text-color;
	margin: 0;
	min-width: 320px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

div,
ul,
li {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type='search'] {
	-webkit-appearance: textfield;
}

a,
button,
input,
textarea,
select {
	&:focus {
		outline: none;
	}
}

.link {
	color: $link-color;

	&:hover {
		color: $link-color-hover;
	}
}

.archived {
	background-color: #5bc0de;
	border-radius: 10px;
	color: #fff;
	font-size: 10px;
	line-height: 10px;
	padding: 3px 8px;
	display: inline-block;
	vertical-align: top;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.no-data {
	text-align: center;
	margin: 15px 0;
}

body.modal-open {
	overflow: hidden;
}

.col {
	padding-right: 16px;
	padding-left: 16px;
}

.row-8 {
	margin-left: -8px;
	margin-right: -8px;

	[class*='col-'] {
		padding-left: 8px;
		padding-right: 8px;
	}

	.col {
		padding-left: 8px;
		padding-right: 8px;
	}
}

.mb-15 {
	margin-bottom: 15px !important;
}

.submissionModal__deleteBtn {
	color: #d9534f;
	border: none;
	background: none;
	font-size: 18px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	margin: -8px;
	text-align: center;
}

.error-message {
	font-size: 16px;
	line-height: 24px;
	display: block;
	text-align: center;

	&__text {
		margin: 0 0 10px;
	}
}

.footer-buttons {
	text-align: right;

	.btn {
		margin-left: 12px;
	}
}

.hidden {
	display: none;
}

.mb-0 {
	margin-bottom: 0 !important;
}
