.Toastify {
	&__toast {
		font-family: inherit;
		border-radius: 3px;
		min-height: 45px;
		width: 254px;

		&--success {
			background-color: #07bc0c;
		}

		&--error {
			background-color: #d43f3a;
			//color: #fff;
		}
	}
}
