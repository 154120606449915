//grid
$fg-columns: 12;
$fg-gutter: 15px;

$fg-class-row: 'row';
$fg-class-col: 'col';
$fg-class-off: 'off';

$fg-breakpoints: ((xs), (sm, 768px), (md, 992px), (lg, 1170px), (xl, 1344px));

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1344px,
);

//media
$breakpoints: (
		phone-xs: 440px,
		phone: 768px,
		tablet: 1024px,
		desktop: 1142px,
);

// base color
$alto: #ddd;
$azure: #2e6da4;
$astral: #337ab7;
$st-tropaz: #286090;
$viking: #5bc0de;

// fonts
$main-font-size: 14px;
$main-line-height: 20px;
$Helvetica: 'Helvetica', sans-serif;
$base-font-family: $Helvetica;

$title-color: #152536;
$text-color: #333;
$icon-color: $azure;

$light-bcgr: #f5f5f5;

$main-border: 1px solid $alto;

$cell-padding: 16px 8px;

// inputs
$input-border-color: #ccc;
$input-color: #333;
$input-placeholder-color: #ccc;
$input-font-size: 14px;
$input-line-height: 20px;

// buttons
$btn-primary: $astral;
$btn-primary-hover: $st-tropaz;
$btn-primary-text: #fff;

$btn-secondary: $astral;
$btn-secondary-hover: $st-tropaz;
$btn-secondary-border: $azure;
$btn-secondary-text: $astral;

$btn-danger: #d9534f;
$btn-danger-hover: #d9534f;
$btn-danger-border: #d9534f;
$btn-danger-text: #fff;

$btn-success: #07bc0c;
$btn-success-hover: #068d0a;
$btn-success-border: #07bc0c;
$btn-success-text: #fff;

$btn-info: $viking;
$btn-info-hover: #51acc7;
$btn-info-border: $viking;
$btn-info-text: #fff;

$link-color: $astral;
$link-color-hover: $st-tropaz;
