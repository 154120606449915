@import '/node_modules/include-media/dist/_include-media.scss';
@import '../../../styles/utils/vars';

.header {
	text-align: left;
	border-bottom: 5px double #d2160a;
	padding: 15px 0 26px;
	margin-bottom: 26px;
	@include media('<phone') {
		padding: 15px 0 10px;
		margin-bottom: 20px;
	}

	&__wrap {
		display: flex;
		justify-content: space-between;
		position: relative;
		@include media('<phone') {
			display: block;
		}
	}
}

.logo {
	font-size: 0;
	line-height: 0;
	width: 157px;
	display: block;

	img {
		width: 100%;
		display: block;
	}

	@include media('<phone') {
		width: 100px;
	}
}

.nav {
	margin: -15px 0 0;
	padding: 0;
	list-style: none;
	display: flex;
	@include media('<phone') {
		margin: 15px -15px -10px;
		border-top: 1px solid #e7e7e7;
		display: none;
	}

	&__link {
		color: #777;
		padding: 15px 12px;
		font-size: 14px;
		line-height: 20px;
		display: block;
		font-family: Helvetica, Arial, sans-serif;
		@include media('<tablet') {
			padding: 15px 8px;
		}

		@include media('<phone') {
			padding: 15px;
		}

		&:hover {
			color: #333;
		}
	}
}

.navbarToggle {
	position: absolute;
	top: -4px;
	right: 0;
	padding: 9px 10px;
	margin: 0;
	background: none;
	border: 1px solid #ddd;
	border-radius: 4px;
	display: none;
	@include media('<phone') {
		display: block;
	}

	&:active {
		background: #ddd;
	}

	&__line {
		display: block;
		width: 22px;
		height: 2px;
		border-radius: 1px;
		background: #888;
		margin-top: 4px;

		&:first-child {
			margin-top: 0;
		}
	}
}

.open {
	@include media('<phone') {
		display: block;
	}
}
