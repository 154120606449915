.waiting-message {
	display: flex;
	flex-direction: row;
	align-items: center;

	&__text {
		padding-left: 10px;
	}

	&__icon {
		width: 20px;
		height: 20px;
	}
}
