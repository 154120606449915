@import '/node_modules/include-media/dist/_include-media.scss';
@import 'styles/utils/vars';

.btn {
	min-height: 32px;
	font-size: 14px;
	line-height: 20px;
	border-radius: 3px;
	padding: 6px 14px;
	outline: none;
	border: 1px solid transparent;
	background-color: transparent;
	cursor: pointer;
	margin: 0;
	position: relative;
	display: inline-block;
	vertical-align: top;
	text-align: center;
	@include media('<phone') {
		padding: 6px 10px;
		min-width: 80px;
	}

	.preloader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		visibility: hidden;
	}

	&_primary {
		background-color: $btn-primary;
		color: #fff;

		&:hover {
			background-color: $btn-primary-hover;
			color: #fff;
		}

		.preloader__inner div {
			border-color: transparent #fff #fff !important;
		}
	}

	&_primary-outline {
		background-color: transparent;
		border-color: $btn-secondary-border;
		color: $btn-secondary-text;

		&:hover {
			color: #fff;
			background-color: $btn-secondary-hover;
		}

		.preloader__inner div {
			border-color: transparent $btn-primary $btn-primary !important;
		}
	}

	&_danger {
		background-color: $btn-danger;
		border-color: $btn-danger-border;
		color: $btn-danger-text;

		&:hover {
			color: $btn-danger;
			background-color: #fff;
		}

		.preloader__inner div {
			border-color: transparent #fff #fff !important;
		}
	}

	&_danger-outline {
		background-color: transparent;
		border-color: $btn-danger-border;
		color: $btn-danger-border;

		&:hover {
			color: #fff;
			background-color: $btn-danger;
		}

		.preloader__inner div {
			border-color: transparent $btn-danger $btn-danger !important;
		}
	}

	&_success {
		background-color: $btn-success;
		border-color: $btn-success-border;
		color: $btn-success-text;

		&:hover {
			color: $btn-success;
			background-color: #fff;
		}

		.preloader__inner div {
			border-color: transparent #fff #fff !important;
		}
	}

	&_success-outline {
		background-color: transparent;
		border-color: $btn-success-border;
		color: $btn-success-border;

		&:hover {
			color: #fff;
			background-color: $btn-success;
		}

		.preloader__inner div {
			border-color: transparent $btn-success $btn-success !important;
		}
	}

	&_info {
		background-color: $btn-info;
		color: #fff;

		&:hover {
			background-color: $btn-info-hover;
			color: #fff;
		}

		.preloader__inner div {
			border-color: transparent #fff #fff !important;
		}
	}

	&_square {
		padding: 0 5px;
		min-width: 38px;
		height: 32px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		[class^='icon-'],
		[class*=' icon-'] {
			vertical-align: top;
			line-height: inherit;
		}
	}

	&_loading {
		pointer-events: none;

		.preloader {
			opacity: 1;
			visibility: visible;
		}

		.btn__text {
			opacity: 0;
			visibility: hidden;
		}
	}

	&.disabled,
	&[disabled] {
		border-color: #ccc;
		background-color: #ccc;
		color: #fff;
		cursor: default;

		.preloader__inner div {
			border-color: transparent #fff #fff !important;
		}
	}
}

.btn-mobile-block {
	@include media('<phone') {
		display: block;
		width: 100%;
	}
}
