@keyframes ldio-udmwyfddl3p {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.preloader {
	width: 48px;
	height: 48px;
	display: inline-block;
	overflow: hidden;
}

.preloader__inner div {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 6px solid #337ab7;
	border-top-color: transparent;
	border-radius: 50%;
	animation: ldio-udmwyfddl3p 0.9345794392523364s linear infinite;
	top: 0;
	left: 0;
}

.preloader__inner {
	width: 100%;
	height: 100%;
	position: relative;
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}

.preloader__inner div {
}
