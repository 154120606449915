$fontsDir: '../../assets/fonts/';
@font-face {
	font-family: 'icomoon';
	src:
		url('#{$fontsDir}icomoon.woff?baxbj3') format('woff'),
		url('#{$fontsDir}icomoon.svg?baxbj3#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-void::before {
	content: '\e90e';
}

.icon-upload::before {
	content: '\e900';
}

.icon-chevron-down::before {
	content: '\e901';
}

.icon-close::before {
	content: '\e902';
}

.icon-check::before {
	content: '\e903';
}

.icon-bin::before {
	content: '\e904';
}

.icon-add::before {
	content: '\e905';
}

.icon-edit::before {
	content: '\e906';
}

.icon-italic::before {
	content: '\e907';
}

.icon-underline::before {
	content: '\e908';
}

.icon-list::before {
	content: '\e90b';
}

.icon-bold::before {
	content: '\e90a';
}

.icon-header::before {
	content: '\e909';
}

.icon-success::before {
	content: '\e90d';
}

.icon-error::before {
	content: '\e90c';
}
